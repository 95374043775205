import React from 'react';
import './ContactPage.css';

function ContactPage() {
  return (
    <div className="contact-page">
      <div className="contact-wrapper">
        <div className="contact-info">
          <h1>연락처</h1>
          <h2>플로리다 말씀교회</h2>
          <p>7509 Van Dyke Rd, Odessa, FL 33556</p>
          <p><strong>Phone:</strong> (213)-804-9674</p>
          <p>Email: gimoseskim68@gmail.com</p>
        </div>

        <div className="map-container">
          <iframe 
            title="Church Location Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.1503542220826!2d-82.56899732429734!3d28.12925747594325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c295639085f7ab%3A0xf1c4d2e1b5f3dc8b!2s7509%20Van%20Dyke%20Rd%2C%20Odessa%2C%20FL%2033556!5e1!3m2!1sen!2sus!4v1723863142896!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0, width: "100%", height: "100%" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;

import React from 'react';
import './YouTubePage.css';

function YouTubePage() {
  return (
    <div className="youtube-page">
      <h1>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
          alt="YouTube"
          className="youtube-logo" 
        />
      </h1>
      <div className="video-grid">
        {/* First Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src = "https://www.youtube.com/embed/qTX715-j-wM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-(첫째, 예배 시리즈) 아벨의 제물을 열납하신 여호와 <br /><span className="eng-text">(1/12/2024)</span>
          </p>
        </div>

        {/* Second Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src = "https://www.youtube.com/embed/yOWnIzX7hWE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-여호와께서 그들 앞에 행하사 <br /><span className="eng-text">(1/5/2024)</span>
          </p>
        </div>

        {/* Third Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/6pEiaWwpue4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-네 주인의 즐거움에 참여할지어다 <br /><span className="eng-text">(12/29/2024)</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default YouTubePage;

import React, { useState } from 'react';
import './NewsPage.css'; // Link to the CSS
import candles from './candles.jpg'; // Import the candles image

const announcements = Array.from({ length: 33 }, (_, index) => {
  const startDate = new Date(2024, 5, 2); // June 2, 2024
  const currentDate = new Date(startDate);
  currentDate.setDate(startDate.getDate() + index * 7); // Add 7 days per announcement
  return {
    id: index + 1,
    date: currentDate.toISOString().split('T')[0], // Format date as YYYY-MM-DD
    title: '소식',
  };
});

// Reverse the announcements array
const reversedAnnouncements = [...announcements].reverse(); // Creates a new reversed array

// Number of announcements per page
const announcementsPerPage = 7;

function NewsPage() {
  const [currentPage, setCurrentPage] = useState(1);

  // Total number of pages
  const totalPages = Math.ceil(reversedAnnouncements.length / announcementsPerPage);

  // Determine the announcements to display on the current page
  const indexOfLastAnnouncement = currentPage * announcementsPerPage;
  const indexOfFirstAnnouncement = indexOfLastAnnouncement - announcementsPerPage;
  const currentAnnouncements = reversedAnnouncements.slice(indexOfFirstAnnouncement, indexOfLastAnnouncement);

  // Handle first, previous, next, and last page navigation
  const goToFirstPage = () => setCurrentPage(1);
  const goToPreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  const goToNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  const goToLastPage = () => setCurrentPage(totalPages);

  const goToPage = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="news-page">
      <div className="header-image-container">
        <img src={candles} alt="Candles" className="header-image" />
        <div className="header-text">
          <h1>교회소식</h1>
          <p>Announcements</p>
        </div>
      </div>

      <table className="news-table">
        <thead>
          <tr>
            <th>No</th>
            <th>제목</th>
            <th>게시일</th>
          </tr>
        </thead>
        <tbody>
          {currentAnnouncements.map((announcement, index) => (
            <tr key={announcement.id}>
              <td>{indexOfFirstAnnouncement + index + 1}</td>
              <td>
                <a href={`/announcement/${announcement.id}`} className="announcement-link">
                  {announcement.title}
                </a>
              </td>
              <td>{announcement.date}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        <button onClick={goToFirstPage} disabled={currentPage === 1}>&laquo;&laquo;</button>
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>&laquo;</button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={index + 1 === currentPage ? 'active' : ''}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>&raquo;</button>
        <button onClick={goToLastPage} disabled={currentPage === totalPages}>&raquo;&raquo;</button>
      </div>
    </div>
  );
}

export default NewsPage;
